<template>
    <div>
        <div style="height: 2px !important">
            <v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear>
        </div>
        <v-row dense class="">
            <!-- CLIENT DETAILS / PROFILE / NOTES -->
            <v-col cols="" class="grey lighten-4" style="height: calc(100vh - 60px) !important; overflow-y: hidden">
                <v-row dense>
                    <v-col cols="12" class="white">
                        <div class="text-h5 primary--text d-flex px-5 py-5">
                            Site:
                            <span class="font-weight-bold" v-if="$route.params.id === 'new'">New</span>
                            <span class="font-weight-bold" v-else>{{ site?.siteName }}</span>
                        </div>
                        <v-tabs background-color="grey darken-2" dark v-model="siteTab">
                            <v-tab href="#details"> Details </v-tab>
                            <v-tab href="#cabins"> Cabins </v-tab>
                            <v-tab href="#comparison"> Comparison Data </v-tab>
                            <!-- <v-tab href="#notes"> Notes </v-tab> -->
                            <!-- DETAILS -->
                            <v-tab-item value="details" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row>
                                    <v-col cols="6">
                                        <v-row dense class="">
                                            <!--- DETAILS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">SITE DETAILS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="8"> <TextField BackgroundColor="white" label="Site Name" v-model="site.siteName" /> </v-col>
                                            <v-col cols="4"> <TextField BackgroundColor="white" label="Box Name" v-model="site.boxName" /> </v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="flex-grow-1">
                                                    <DropdownAdvanced
                                                        BackgroundColor="white"
                                                        :items="organisations"
                                                        itemtext="name"
                                                        itemvalue="entityId"
                                                        label="Organisation"
                                                        v-model="site.orgId"
                                                        @change="updateOrganisation"
                                                    />
                                                </div>
                                                <div>
                                                    <v-btn icon @click="copyOrganisationDetails()" :disabled="site.orgId === ''"><v-icon>icons8-copy</v-icon></v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="flex-grow-1">
                                                    <DropdownAdvanced
                                                        BackgroundColor="white"
                                                        :disabled="site.orgId === ''"
                                                        :items="filterProjectsByOrgId()"
                                                        itemtext="name"
                                                        itemvalue="entityId"
                                                        label="Project"
                                                        v-model="site.projectId"
                                                    />
                                                </div>
                                                <div>
                                                    <v-btn icon @click="copyProjectDetails()" :disabled="site.projectId === ''"><v-icon>icons8-copy</v-icon></v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact First Name *"
                                                    v-model="site.contactFirstName"
                                                    :validate="validate.contactFirstName"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Contact Last Name *"
                                                    v-model="site.contactLastName"
                                                    :validate="validate.contactLastName"
                                                />
                                            </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Contact Job Title" v-model="site.contactJobRole" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Telephone *" v-model="site.telephone" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Email" v-model="site.email" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Website" v-model="site.website" /> </v-col>
                                            <!--- ADDRESS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">ADDRESS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12">
                                                <vuetify-google-autocomplete
                                                    v-if="$route.params.id === 'new'"
                                                    label="Address Lookup"
                                                    autocomplete="donotcomplete"
                                                    solo
                                                    background-color="white"
                                                    name="myapp-name"
                                                    ref="addressInput"
                                                    types=""
                                                    id="map"
                                                    classname="form-control"
                                                    v-on:placechanged="getAddressData"
                                                    country="gb"
                                                    clearable
                                                >
                                                </vuetify-google-autocomplete
                                            ></v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 1" v-model="site.addressLine1" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 2" v-model="site.addressLine2" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Address Line 3" v-model="site.addressLine3" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Town" v-model="site.town" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="County" v-model="site.county" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Postcode" v-model="site.postcode" /> </v-col>
                                            <v-col cols="12">
                                                <DropdownSimple BackgroundColor="white" :items="countries" label="Country" v-model="site.country" />
                                            </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Latitude" v-model="site.latitude" /> </v-col>
                                            <v-col cols="6"> <TextField BackgroundColor="white" label="Longitude" v-model="site.longitude" /> </v-col>
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">DIVISION / REGION</div>
                                            </v-col>
                                            <v-col cols="6">
                                                <DropdownSimple BackgroundColor="white" :items="org.divisions" label="Division" v-model="site.division" />
                                            </v-col>
                                            <v-col cols="6">
                                                <DropdownSimple BackgroundColor="white" :items="org.regions" label="Region" v-model="site.region" />
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row dense>
                                            <!--- STATUS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">STATUS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="6">
                                                <DropdownSimple BackgroundColor="white" :items="siteStatus" label="Status" v-model="site.status" />
                                            </v-col>
                                            <v-col cols="6">
                                                <DropdownSimple BackgroundColor="white" :items="modes" label="Mode" v-model="site.mode" />
                                            </v-col>
                                            <!-- START -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">DATES</div>
                                            </v-col>
                                            <v-col cols="6">
                                                <!--                                                {{ site.startDateTime }}-->
                                                <DatePicker
                                                    BackgroundColor="white"
                                                    label="Start Date"
                                                    v-model="site.startDateTime"
                                                    :dense="true"
                                                    :clearable="false"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <!--                                                {{ site.endDateTime }} -->
                                                <DatePicker BackgroundColor="white" label="End Date" v-model="site.endDateTime" :dense="true" :clearable="false" />
                                            </v-col>
                                            <!-- TYPE -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">TYPE</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="6"><DropdownSimple BackgroundColor="white" :items="siteTypes" label="Type" v-model="site.type" /> </v-col>
                                            <v-col cols="6">
                                                <TextField BackgroundColor="white" label="Number of Cabins" v-model.number="site.cabins" type="number" />
                                            </v-col>
                                            <v-col cols="12"
                                                ><DropdownMultiple
                                                    BackgroundColor="white"
                                                    :items="accommodationTypes"
                                                    label="Accommodation"
                                                    v-model="site.accommodation"
                                                />
                                            </v-col>
                                            <!-- COSTS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">COSTS</div>
                                            </v-col>
                                            <v-col cols="6" v-if="site.type === 'Diesel'">
                                                <TextField BackgroundColor="white" label="Cost Per Litre" v-model.number="site.costPerLitre" type="number" />
                                            </v-col>
                                            <v-col cols="6" v-if="site.type !== 'Diesel'">
                                                <TextField BackgroundColor="white" label="Cost Per kWh" v-model.number="site.costPerkWh" type="number" />
                                            </v-col>
                                            <v-col cols="6" v-if="site.type !== 'Diesel'">
                                                <TextField
                                                    BackgroundColor="white"
                                                    label="Standing Charge (per day)"
                                                    v-model.number="site.costStandingCharge"
                                                    type="number"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <TextField BackgroundColor="white" label="Rental Charge (per day)" v-model.number="site.rentalCost" type="number" />
                                            </v-col>
                                            <!-- PRODUCT -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">PRODUCT</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"
                                                ><DropdownSimple BackgroundColor="white" :items="products" label="Product" v-model="site.productId" />
                                            </v-col>
                                            <!-- PROJECT -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">NOTES</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12"><TextArea background-color="white" label="Notes" v-model="site.notes" rows="10"></TextArea></v-col>
                                            <v-col cols="12" v-if="site.createdUserId !== ''" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">METADATA</div>
                                            </v-col>
                                            <v-col cols="12" v-if="site.createdUserId !== ''"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="12" v-if="site.createdUserId !== ''">
                                                <div class="grey lighten-2 caption pa-5">
                                                    ID: {{ site.entityId }}<br />
                                                    Created: {{ MIX_findUserName(site.createdUserId) }} |
                                                    {{ $dayjs(site.createdDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    Modified: {{ MIX_findUserName(site.modifiedUserId) }} |
                                                    {{ $dayjs(site.modifiedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    <div v-if="site.deleted">
                                                        Deleted: {{ MIX_findUserName(site.deletedUserId) }} |
                                                        {{ $dayjs(site.deletedDateTime).format('DD MMM YY HH:mm:ss') }}<br />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="cancelSite()">Cancel</AppButton>
                                        </div>
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveSite()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--                                <v-row>-->
                                <!--                                    <pre>{{ site }}</pre>-->
                                <!--                                </v-row>-->
                            </v-tab-item>
                            <v-tab-item value="cabins" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row dense class="">
                                            <!--- DETAILS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">CABIN DETAILS</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="4" v-for="(cabin, index) in site.cabins" :key="index" class="d-flex align-center justify-end">
                                                <div style="width: 50px !important" class="text-center">{{ MIX_indexToLetters(index) }}</div>
                                                <div class="flex-grow-1">
                                                    <TextField BackgroundColor="white" label="Cabin Name" v-model="cabinNames[MIX_indexToLetters(index)]" />
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="cancelSite()">Cancel</AppButton>
                                        </div>
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveSite()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--                                <v-row>-->
                                <!--                                    <pre>{{ site }}</pre>-->
                                <!--                                </v-row>-->
                            </v-tab-item>
                            <v-tab-item value="comparison" class="grey lighten-3 pa-5" style="height: calc(100vh - 225px) !important; overflow-y: scroll">
                                <v-row>
                                    <v-col>
                                        <div>
                                            <div class="d-flex align-center mb-2">
                                                <div class="text-h7 font-weight-bold primary--text">GET TEMPERATURE USAGE AVERAGES</div>
                                            </div>

                                            <v-divider class="mb-4" />
                                            <!--                    Date Picker-->
                                            <div class="d-flex justify-space-between">
                                                <!--                                                <div>{{ startDate }} | {{ startDateUnix }} | {{ endDate }} {{ endDateUnix }}</div>-->
                                                <div style="width: 50%" class="pr-3">
                                                    <DatePicker
                                                        BackgroundColor="white"
                                                        label="Monitoring Start Date"
                                                        :max="this.$dayjs().format('YYYY-MM-DD')"
                                                        v-model="startDate"
                                                        :dense="true"
                                                        :clearable="false"
                                                    />
                                                </div>
                                                <!-- EndDate -->
                                                <div style="width: 50%">
                                                    <DatePicker
                                                        BackgroundColor="white"
                                                        :max="this.$dayjs().format('YYYY-MM-DD')"
                                                        label="Monitoring End Date"
                                                        v-model="endDate"
                                                        :clearable="false"
                                                        :dense="true"
                                                    />
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-end mt-4">
                                                <VBtn @click.native="getComparisonData" class="primary">Get Averages</VBtn>
                                            </div>
                                            <!--                    Last Week, Last Month, Last Quarter, Last Year-->
                                            <!--                                            <div>-->
                                            <!--                                                <div class="d-flex mt-4">-->
                                            <!--                                                    <VBtn @click.native="setDateField('week')" class="primary mr-1">Last Week</VBtn>-->
                                            <!--                                                    <VBtn @click.native="setDateField('month')" class="primary mx-1">Last Month</VBtn>-->
                                            <!--                                                    <VBtn @click.native="setDateField('quarter')" class="primary mx-1">Last Quarter</VBtn>-->
                                            <!--                                                    <VBtn @click.native="setDateField('year')" class="primary ml-1">Last Year</VBtn>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row dense class="">
                                            <!--- DETAILS -->
                                            <v-col cols="12" class="d-flex align-center">
                                                <div class="mt-4 text-h7 font-weight-bold primary--text">COMPARISON DATA</div>
                                            </v-col>
                                            <v-col cols="12"><v-divider class="mb-2" /></v-col>
                                            <v-col cols="4" v-for="(usage, index) in energyUsageMap" :key="index" class="d-flex align-center justify-end">
                                                <div style="width: 50px !important" class="text-center">{{ index }} °C</div>
                                                <div class="flex-grow-1 d-flex">
                                                    <TextField BackgroundColor="white" label="Average Usage" v-model.number="energyUsageMap[index]" type="number" />
                                                    <app-button
                                                        @click.native="energyUsageMap[index] = averageData[index]"
                                                        buttonclass="primary mt-1"
                                                        :disabled="averageData[index] === 0"
                                                        >{{ '<-' }} Copy
                                                    </app-button>
                                                    <TextField
                                                        BackgroundColor="white"
                                                        label="Average from Server"
                                                        disabled
                                                        v-model.number="averageData[index]"
                                                        type="number"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row dense class="my-4 justify-end">
                                    <v-col cols="2" class="d-flex justify-end align-center">
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="grey" @click.native="cancelSite()">Cancel</AppButton>
                                        </div>
                                        <div class="flex-grow-1" style="width: 50%">
                                            <AppButton block buttonclass="secondary" @click.native="saveSite()">Save</AppButton>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--                                <v-row>-->
                                <!--                                    <pre>{{ site }}</pre>-->
                                <!--                                </v-row>-->
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-col>

            <!-- CONFIRMATION BOX -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirmed you want to delete this Vet</p>
                <strong>{{ site.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeletesite">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'ClientView',
    // data
    data: () => ({
        loading: false,
        // GENERAL
        confirmBox: false,
        siteTab: 'details',
        site: {},
        org: {},
        validate: {},
        modes: [
            { text: 'Monitoring', value: 'monitoring' },
            { text: 'Peakshaving', value: 'peakshaving' },
            { text: 'Sequencer', value: 'sequencer' },
            { text: 'Maintenance', value: 'maintenance' }
        ],
        cabinNames: {},
        energyUsageMap: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
            19: 0
        },
        averageData: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
            19: 0
        },
        accommodationTypes: [
            { text: 'Single Story', value: 'singlestory' },
            { text: 'Double Story', value: 'doublestory' },
            { text: 'Modular Cabins', value: 'modularcabins' }
        ],
        startDate: null,
        endDate: null,
        startDateUnix: 0,
        endDateUnix: 0
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile'
        })
    },
    // methods
    methods: {
        // Initialise
        async initialise() {
            this.loading = true
            //get users
            try {
                await this.MIX_getUsers()
            } catch (error) {
                console.error(error)
            }
            // Get Oraganisations
            try {
                await this.MIX_getOrganisations()
            } catch (error) {
                console.error(error)
            }
            // Get Projects
            try {
                await this.MIX_getProjects()
            } catch (error) {
                console.error(error)
            }
            // Get Lookups
            try {
                await this.MIX_getLookups()
            } catch (error) {
                console.error(error)
            }
            // Get Site
            if (this.$route.params.id !== 'new') {
                let siteResult = await this.REDIS_read('site', this.$route.params.id)
                let site = siteResult.data
                // Parse the cabin names
                if (site.cabinNames !== '' && site.cabinNames !== undefined) {
                    this.cabinNames = JSON.parse(site.cabinNames)
                }
                // Parse the energy usage map
                if (site.comparisonEnergyMap !== '' && site.comparisonEnergyMap !== undefined) {
                    this.energyUsageMap = JSON.parse(site.comparisonEnergyMap)
                }
                this.site = site
                // Get siteanisation Profile
            } else {
                this.site = { ...this.$schema.site }
                this.updateOrganisation()
            }
            this.loading = false
        },
        /**
         * Set the date field, based on the range passed in
         * @param range
         */
        setDateField(range) {
            if (range === 'week') {
                this.startDate = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            } else if (range === 'month') {
                this.startDate = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            } else if (range === 'quarter') {
                this.startDate = this.$dayjs().subtract(1, 'day').subtract(3, 'month').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            } else if (range === 'year') {
                this.startDate = this.$dayjs().subtract(1, 'day').subtract(1, 'year').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            }
        },
        async saveSite() {
            try {
                let site = { ...this.site }
                site.orgName = this.MIX_findOrganisationName(this.site.orgId)
                site.projectName = this.MIX_findProjectName(this.site.projectId)
                site.modifiedUserId = this.GET_currentUserProfile.entityId
                site.modifiedDateTime = this.$dayjs().unix()
                site.deleted = false
                site.deletedDateTime = null
                site.deletedUserId = null
                site.costPerkWh = parseFloat(site.costPerkWh)
                site.costPerLitre = parseFloat(site.costPerLitre)
                site.comparisonkWh = parseFloat(site.comparisonkWh)

                // Stringify the cabin names
                site.cabinNames = JSON.stringify(this.cabinNames)
                // Stringify the energy usage map
                site.comparisonEnergyMap = JSON.stringify(this.energyUsageMap)

                let result = null
                if (this.$route.params.id === 'new') {
                    site.id = this.MIX_generateId()
                    site.siteId = site.id
                    site.createdUserId = this.GET_currentUserProfile.entityId
                    site.createdDateTime = this.$dayjs().unix()
                    result = await this.REDIS_createWithId('site', site, site.id)
                } else {
                    result = await this.REDIS_update('site', site.id, site)
                }
                if (result.success) {
                    this.MIX_alertBox({ show: true, message: 'Project Saved', color: 'success', timeout: '2000' })
                    // this.$router.push('/site-admin')
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: 'Error Saving Project', color: 'error', timeout: '4000' })
                console.error(error)
            }
        },
        getAddressData: function (addressData, placeResultData) {
            let placeResult = this.MIX_googlePlacesConversion(JSON.stringify(placeResultData))
            this.site.AddressLine1 = placeResult.addressLine1
            this.site.AddressLine2 = placeResult.addressLine2
            this.site.town = placeResult.town
            this.site.county = placeResult.county
            this.site.postcode = placeResult.postcode
            this.site.country = placeResult.country
            this.site.latitude = placeResult.lat
            this.site.longitude = placeResult.lng
        },
        filterProjectsByOrgId() {
            if (this.site.orgId !== '') {
                return this.projects.filter((project) => project.orgId === this.site.orgId)
            } else {
                return []
            }
        },
        copyOrganisationDetails() {
            let org = this.organisations.find((org) => org.entityId === this.site.orgId)
            this.site.contactFirstName = org.contactFirstName
            this.site.contactLastName = org.contactLastName
            this.site.contactJobRole = org.contactJobRole
            this.site.addressLine1 = org.addressLine1
            this.site.addressLine2 = org.addressLine2
            this.site.town = org.town
            this.site.county = org.county
            this.site.postcode = org.postcode
            this.site.country = org.country
            this.site.telephone = org.telephone
            this.site.email = org.email
            this.site.website = org.website
            this.site.latitude = org.latitude
            this.site.longitude = org.longitude
        },
        copyProjectDetails() {
            let project = this.projects.find((project) => project.entityId === this.site.projectId)
            this.site.contactFirstName = project.contactFirstName
            this.site.contactLastName = project.contactLastName
            this.site.contactJobRole = project.contactJobRole
            this.site.telephone = project.telephone
            this.site.email = project.email
            this.site.website = project.website
        },
        cancelSite() {
            this.$router.push('/site-admin')
        },
        updateOrganisation() {
            this.org = this.organisations.find((org) => org.entityId === this.site.orgId)
        },
        async getComparisonData() {
            this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
            this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
            let url = `${process.env.VUE_APP_API_URL}publicV2/calculateSiteAverages`
            let t = this
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    siteId: t.site.entityId,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    aggregation: 'avg',
                    bucketSize: 3600000
                })
                .then((res) => {
                    // console.log(res.data.data)
                    if (res.data.success) {
                        t.averageData = res.data.data
                    }
                })
        }
    },
    watch: {},
    created() {
        this.initialise()
        this.startDate = this.$dayjs().subtract(8, 'day').format('YYYY-MM-DD')
        this.endDate = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
        this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
    }
}
</script>

<style scoped>
.d-flex {
    gap: 15px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
}
</style>
